:root{
  // colors
  --primaria: #de0059;
  --contrast: #008f72;
  --white: #ffffff;
  --azul: #21374C;
  --texto: #3f3f3f;
  --azul-opacidade: rgba(33, 55, 76, .6);
  --border-color: #EEEEEE;
  --cinza: #BDBDBD;
  --cinza-forte: #999999;
  --cinza-fraco: rgba(196, 196, 196, 0.3);
  --link: #16ADDE;
  --botao-cor: #8794A0;
  --botao-azul: #006FD5;
  --background-cinza: #F7F7F7;

  //------------
  // HEADER
  //------------
  --header-background: #FFFFFF;
  --header-text-primary: #DE0059;
  --header-text-default: rgba(33, 55, 76, .6);
  --header-text-title: #999999;
  --header-border: #27272a;
  --header-search-background: #FFFFFF;
  --header-logo: #DE0059;

  //------------
  //BODY
  //------------
  --body-background: ;

  //------------
  // TEXT
  //------------
  --text-primary: #DE0059;
  --text-secundary: #16ADDE;
  --text-default: #21374C;
  --text-gray: #8692A6;
  --text-dark: #21374C;

}